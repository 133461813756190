@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Manrope:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Product+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
/* Global */
body {
  background: #FDFDFD;
}

a {
  display: inline;
  width: auto;
  text-decoration: none;
}

h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 4em;
}

h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 2.25em;
}

h3 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 1.5em;
    color: #FFFFFF;
}

a h3 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1.5em;
  color: #FFFFFF;
}


p {
  font-family: "Manrope";
  font-weight: 300;
  color: #2E2E2E;
}

strong {
  font-weight: 500;
}

#dark {
  color: #D8D8D8;
}

#subheader {
  font-family: "Product Sans";
  font-weight: 300;
  color: #DFDFDF;
  margin: 0;

}

/* Navbar */
.navbar-wrapper {
  margin: 1vh 3vw 0 0;
  display: flex;
  justify-content: flex-end;
}

.nav-link {
  font-family: "Product Sans";
  font-weight: 300;
  text-transform: lowercase;
  font-size: 1.1em;
}

.nav-link:hover {
  color: #000000;
  text-decoration: underline;
}

/* Header */


.header-wrapper {
  height: 350px;
  margin-bottom: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  text-align: center;
}

.icons {
  width: auto;
  display: inline;
}
.icons svg {
  display: inline;
  margin-right: 0.5vw;
}

.action-btn svg {
  display: inline;
  margin-right: 2.5px;
}

.action-btn a {
  color: #FFFFFF;
}

.icons a {
  color: black;
}

.icons a:hover {
  color: #446CE5;
}

.action-btn-wrapper {
  display: inline;
  width: auto;
}

.action-btn {
  display: inline;
  width: 158px;
  height: 30px;
  background: #446CE5;
  border-radius: 2.5px;
  border: none;
  font-family: "Manrope", sans-serif;
  color: #FFFFFF;
  font-size: 0.85em;
  font-weight: 300;
}

.action-btn:hover {
  background: #3E60C7;
}


/* About */
.about-wrapper {
  padding: 0 5vw 0 5vw;
  background: #060913;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.about-text {
  width: 95%;
}

.about-image img {
  max-height: 500px;
  padding-right: 5px;
  max-width: 100%;
}

.about-content {
  width: 75%;
  padding-left: 5px;
}

/* Projects */
.projects-wrapper {
  margin-top: 7.5vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects-header {
  text-align: center;
}

.projects {
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.project {
  width: 100%;
  margin: 0 auto;
  height: 375px;
  position: relative;
  margin-right: 2.5vw;
  margin-bottom: 5vh;
  border-radius: 10px;
}

.project-desc {
  padding: 0 1vw 0.5vh 1vw;
  position: absolute;
  bottom: 0;
}

.project-desc p {
  color: #FFFFFF;
}

.project-desc h3:hover {
  color:#2477F3;
}



/* Media Queries */
@media only screen and (max-width: 600px) {
  .about-image img {
    display: none;
  }

  .about-wrapper {
    height: 450px;
    padding: 5vh 5vw 5vh 5vw;
  }

  .about-text, .about-content {
    width: 100%;
  }


  /* Increasing project card size and padding. */
  .project {
    width: 100%;
  }

  .project-desc {
    padding: 0 3vw 0 3vw;
  }


}

